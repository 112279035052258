import {
    Link,

} from '@carbon/react';
import './Footer.scss';

type Props = {
    privacyUrl?: string,
    className?: string
}

const Footer = ({privacyUrl, className}: Props) => {
    const link = privacyUrl ? privacyUrl : 'https://www.ventum-consulting.com/en/data-protection-at-a-glance/';
    return (<div className={className}><Link href={link}>Privacy</Link></div>)
}

export default Footer;