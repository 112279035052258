import MvpHeader from '../components/MvpHeader/MvpHeader';
import { MenuPoint } from '../types/MenuPoint';
import './Layout.scss';
import Footer from "../components/Footer/Footer";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
  menuPoints: MenuPoint[];
  setCurrentTheme: CallableFunction;
  appPrefix: string;
  activePage: number;
  setActivePage: CallableFunction;
  privacyUrl?: string;
  baseColor: string;
  logo: string;
}

export default function Layout({
  children,
  menuPoints,
  setCurrentTheme,
appPrefix,
  activePage,
  setActivePage,
  privacyUrl,
  baseColor,
  logo, 
}: LayoutProps) {
  const location = useLocation();
  const className = location.pathname === '/submission' ? 'footer-sticky' : 'footer';

  return (
    <div className="viewport">
      <MvpHeader
        menuPoints={menuPoints}
        prefix={appPrefix}
        title="CORA"
        setCurrentTheme={setCurrentTheme}
        activePage={activePage}
        setActivePage={setActivePage}
        baseColor={baseColor}
        logo={logo}
       
      />
      <div className="bodyWrapper">{children}</div>
      <Footer privacyUrl={privacyUrl} className={className}/>
    </div>
  );
}
