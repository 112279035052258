
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Grid,
  Column,
  Modal,
  Search,
 } from '@carbon/react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import './CbamAcademy.scss';
import greenFactory from '../../assets/img/factory.png';
import improvedFactory from '../../assets/img/improved_factory.png';
import React, { useState, useRef, useEffect } from 'react';
import { InfoSection, InfoCard, VideoCard } from '../../components/Info/Info';
import { WatsonHealthAiStatusInProgress } from '@carbon/icons-react';
import SearchFunction from '../../components/Search/Search';
import faqsData from '../../data/Faqs';
import VideoEmbed from '../../components/Vimeo/Vimeo'; 




const CbamAcademy = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);


 
  return (

    <Grid className="landing-page" fullWidth>
<Column lg={16} md={8} sm={4} className="landing-page__banner">
  <Breadcrumb noTrailingSlash>
    <BreadcrumbItem>
      <a href="/">Getting started</a>
    </BreadcrumbItem>
  </Breadcrumb>
  <h1 className="landing-page__heading">CBAM Academy</h1>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r2">
  <Tabs defaultSelectedIndex={0}>
    <TabList className="tabs-group">
      <Tab>Introduction to CBAM</Tab>
      <Tab>Using CORA</Tab>
      <Tab>FAQ & Support</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CBAM?</h2>
            <p className="landing-page__p">
            With the Carbon Border Adjustment Mechanism (CBAM), the EU has introduced a set of rules from October 2023 that will force importers of various key raw materials and intermediate products to take action. From the last quarter of 2023, this border adjustment mechanism provides for a reporting obligation for companies that import goods with a high carbon footprint from non-EU countries.
            </p>
            <Button onClick={() => setOpen(true)}>Watch a short video about CBAM</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
            <p style={{
        marginBottom: '1rem'
      }}>
 <iframe className="modalVideo" src="https://share.synthesia.io/embeds/videos/930963af-4d28-4100-afa8-2aef55be4185" loading="lazy" title="Synthesia video player - CORA Context Help - Welcome" allow="encrypted-media; fullscreen;"></iframe>
        </p>

  </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={greenFactory}
    alt="Carbon illustration"
    width={367}
    height={350}
  />
</Column>
        </Grid>
      </TabPanel>
      <TabPanel>
      <Grid className="tabs-group-content">
          <Column md={4} lg={7} sm={4} className="landing-page__tab-content">
            <h2 className="landing-page__subheading">What is CORA?</h2>
            <p className="landing-page__p">
            CORA, also known as Carbon Optimizer & Reporting Assistent was designed to help you answer your suppliers' CBAM survey as accurately as possible while saving you time and starting in 2026 money. This tool is absolutely free for you and we recommend to use the CBAM Academy to tackle this regulation.
            </p>
            <Button onClick={() => setOpen(true)}>Watch the CORA Survey Walkthrough</Button>
            <Modal open={open} onRequestClose={() => setOpen(false)} passiveModal modalHeading="Introduction to CBAM & CORA" primaryButtonText="Close Window">
        <p style={{ marginBottom: '1rem' }}>
          <iframe  className="modalVideo" loading="lazy"></iframe>
        </p>
      </Modal>
          </Column>
          <Column md={4} lg={{ span: 8, offset: 7 }} sm={4}>
  <img className="landing-page__illo"
    src={improvedFactory}
    alt="Carbon illustration"
    width={450}
    height={405}
  />
</Column>
        </Grid>
      </TabPanel>
      <TabPanel>
        <Grid className="tabs-group-content">
          <Column lg={16} md={8} sm={4} className="landing-page__tab-content">
          <InfoSection heading="Frequently Asked Questions" className="landing-page__r3">
        </InfoSection>       
          </Column>
          <Column lg={16} md={8} sm={4}>
          <SearchFunction details={faqsData}/>
        </Column>
        </Grid>
      </TabPanel>
    </TabPanels>
  </Tabs>
</Column>
<Column lg={16} md={8} sm={4} className="landing-page__r3">
<InfoSection heading="The Principles" className="landing-page__r3">
  <InfoCard
    heading="CORA is Open"
    body="Once you've filled out your survey, you will send the results directly to your supplier while you have access to your results. Export to the official EU Communication template is coming soon."
    
  />
  <InfoCard
    heading="CORA is Modular"
    body="CBAM can be overwhelming. We will only ask you what your supplier really needs to generate a compliant report and nothing more. Complexity will rise in sync with your CBAM skills thanks to CORA."
   
  />
  <InfoCard
    heading="CORA is Safe"
    body="At any step of the way, you'll have total control over your data. While Excel-based solutions often transmit your total production, CORA will use the values only for the necessary calculations."
  
  />
</InfoSection>
</Column>
<Column lg={16} md={8} sm={4} className="shortVideoBox">

<h3>Short Tutorials</h3> 
<p>Browse through all of CORA's tutorial videos to finish your CBAM survey.</p>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video1Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://player.vimeo.com/video/990212854"
                  title="CBAM Academy - Reporting Periods for Operators"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video1Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video2Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://player.vimeo.com/video/990235987"
                  title="CBAM Academy - Calculating Direct Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video2Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video3Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://player.vimeo.com/video/990236147"
                  title="CBAM Academy - Calculating Indirect Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video3Description')}</p>
            </div>
          </div>
        </Column>
       
        <Column lg={4} md={2} sm={2} className="videoColumn">
          <h4>{t('surveyInfo.video4Title')}</h4>  
          <div className="video-container">
          <VideoEmbed
                  src="https://player.vimeo.com/video/990237903"
                  title="CBAM Academy - Calculating Emissions"
                  width="100%"
                  height="auto"
                />
            <div className="video-description">
              <p>{t('surveyInfo.video4Description')}</p>
            </div>
          </div>
        </Column>
        <Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video4Title')}</h4>  
  <div className="video-container">
    <VideoEmbed
      src="https://player.vimeo.com/video/990240561?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
      title="CORA Context Help - Aggregated Goods Category"
      width="100%"
      height="auto"
    />
    <div className="video-description">
      <p>{t('surveyInfo.video4Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video5Title')}</h4>  
  <div className="video-container">
    <VideoEmbed
      src="https://player.vimeo.com/video/990240729?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
      title="CORA Context Help - Carbon Price"
      width="100%"
      height="auto"
    />
    <div className="video-description">
      <p>{t('surveyInfo.video5Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video6Title')}</h4>  
  <div className="video-container">
    <VideoEmbed
      src="https://player.vimeo.com/video/990240831?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
      title="CORA Context Help - Activity Levels"
      width="100%"
      height="auto"
    />
    <div className="video-description">
      <p>{t('surveyInfo.video6Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video7Title')}</h4>  
  <div className="video-container">
    <VideoEmbed
      src="https://player.vimeo.com/video/990240930?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
      title="CORA Context Help - Additional Reporting Parameters"
      width="100%"
      height="auto"
    />
    <div className="video-description">
      <p>{t('surveyInfo.video7Description')}</p>
    </div>
  </div>
</Column>
<Column lg={4} md={2} sm={2} className="videoColumn">
  <h4>{t('surveyInfo.video8Title')}</h4>  
  <div className="video-container">
    <VideoEmbed
      src="https://player.vimeo.com/video/1002415783?h=f9d42a4779&title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479"
      title="Survey Completed"
      width="100%"
      height="auto"
    />
    <div className="video-description">
      <p>{t('surveyInfo.video8Description')}</p>
    </div>
  </div>
</Column>


  </Grid>
  );
};

export default CbamAcademy;
