import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderMenu,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderName,
  HeaderSideNavItems,
  SideNav,
  SideNavItems,
  SkipToContent,
} from '@carbon/react';
import { Link, useSearchParams } from 'react-router-dom';
import { MenuPoint } from '../../types/MenuPoint';
import { MvpRoute } from '../../types/MvpRoute';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import './MvpHeader.scss';
import { Help, Logout } from '@carbon/icons-react';
import { useOidc } from '@axa-fr/react-oidc';

interface MvpHeaderProps {
  menuPoints: MenuPoint[];
  prefix: string;
  title: string;
  setCurrentTheme: CallableFunction;
  activePage: number;
  setActivePage: CallableFunction;
  baseColor: string; 
  logo: string; // Added the missing closing bracket for the interface
}

const MvpHeader = ({
  menuPoints,
  prefix,
  setCurrentTheme,
  activePage,
  setActivePage,
  baseColor,
  logo,
}: MvpHeaderProps) => {
  const { isAuthenticated, logout } = useOidc();
  let [searchParams] = useSearchParams();
  let surveyId = searchParams.get('surveyId');

  console.log('current active page:', activePage);
  const logoSrc = logo ? `data:image/png;base64,${logo}` : null;

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }: any) => (
        <Header aria-label="MVP" className="cora_header" style={{ backgroundColor: baseColor }}>
          <SkipToContent />
          <HeaderMenuButton
            className="header_button"
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName prefix={''}>            {/*empty prefix removes the default carbon logo (IBM)*/}
            {logoSrc && <img src={logoSrc} alt="Logo" className="header-logo" />}
            <p id="supplier-name">{prefix}</p>
      
          </HeaderName>
          <SideNav aria-label="Side navigation" expanded={isSideNavExpanded} isPersistent={false}>
            <SideNavItems>
              <HeaderSideNavItems>
                {menuPoints.map((menuPoint: MenuPoint, index: number) => {
                  return (
                    <HeaderMenu
                      aria-label={menuPoint.name}
                      menuLinkName={menuPoint.name}
                      key={index}
                    >
                      {menuPoint.routes.map((route: MvpRoute, indexRoute: number) => {
                        return route.isStartPage ? null : (
                          <HeaderMenuItem
                            element={Link}
                            to={route.path}
                            key={indexRoute}
                            onClick={() => {
                              setActivePage(route.id);
                            }}
                          >
                            {route.label}
                          </HeaderMenuItem>
                        );
                      })}
                    </HeaderMenu>
                  );
                })}
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>

          <HeaderGlobalBar>
            <HeaderGlobalAction
              aria-label="Support"
              onClick={() => window.open('/academy', '_blank')}
            >
              <Help size={20} style={{ fill: 'black' }} />
            </HeaderGlobalAction>
            <LanguageSwitcher />
            {isAuthenticated &&
              (<HeaderGlobalAction
                aria-label="Logout"
                onClick={() => logout(`/?surveyId=${surveyId}`)}
              >
              <Logout size={20} style={{ fill: 'black' }} />
              </HeaderGlobalAction>)}

            {console.log(typeof setCurrentTheme)}
            {/* <HeaderGlobalAction aria-label="Theme Switcher" tooltipAlignment="start">
              <BrightnessContrast
                size={20}
                onClick={() =>
                  setCurrentTheme((oldTheme: string) =>
                    oldTheme === SWITCH_THEMES[0] ? SWITCH_THEMES[1] : SWITCH_THEMES[0],
                  )
                }
              />
            </HeaderGlobalAction> */}
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default MvpHeader;
