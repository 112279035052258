import {
  Button,
  Checkbox,
  DataTable,
  Select,
  SelectItem,
  ComboBox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  NumberInput,
  IconButton,
  Modal,
  OverflowMenu,
  OverflowMenuItem
} from '@carbon/react';
import { useTranslation } from 'react-i18next';
import { DataTableHeader } from '../../types/DynamicTable';
import { EmissionSource } from '../../types/SurveySupplierInput';
import { StarFilled, Edit, TrashCan, AddLarge } from '@carbon/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import './DynamicEmissionTable.scss';
import AGCGoodsTable from './AGCGoodsTable';
import { ChangeEvent } from 'react';


interface DynamicEmissionTableProps {
  ES: EmissionSource[];
  ESUpdate: React.Dispatch<React.SetStateAction<EmissionSource[]>>;
  PrecursorsList: EmissionSource[];
  FuelGasList: EmissionSource[];
  OtherInputList: EmissionSource[];
  hasAttemptedNextStep: any;
}

interface ComboBoxEvent {
  selectedItem: EmissionSource | null;
}

const DynamicEmissionTable = ({ ES, ESUpdate, PrecursorsList, FuelGasList, OtherInputList, hasAttemptedNextStep }: DynamicEmissionTableProps) => {
  const { t } = useTranslation();
  
  const prevESRef = useRef<EmissionSource[]>([]);
  const [isItemSelected, setIsItemSelected] = useState(false);

  const updateCNselection = (selectedGood: any | null) => {
    if (selectedGood) {
      setIsItemSelected(true);
      setNewEmissionFormData(prevFormData => ({
        ...prevFormData,
        cn_code: selectedGood.cn_code,
      }));
    } else {
      setIsItemSelected(false);
    }
  };

  useEffect(() => {
    const calculateMultiplier = async (emissionId: number, category: string, emissionName?: string, cn_code?: number) => {
      if (emissionName) {
        try {
          const formattedEmissionName = emissionName.replace(/\//g, "%252F");
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/emissions/calculate_multiplier/${emissionId}/${category}/${formattedEmissionName}/${cn_code}`);
          const data = await response.json();
          ESUpdate(prevES =>
            prevES.map(es =>
              es.emission_id === emissionId ?
                { ...es, direct_multiplier: data.direct, indirect_multiplier: data.indirect } : es
            )
          );
        } catch (error) {
          console.error('Error calculating multiplier:', error);
        }
      }
    };

    const newEmissions = ES.filter(es => !prevESRef.current.some(prevEs => prevEs.emission_id === es.emission_id));
    newEmissions.forEach(newEmission => calculateMultiplier(newEmission.emission_id, newEmission.category, newEmission.emission_name, newEmission.cn_code));

    prevESRef.current = ES;
  }, [ES, ESUpdate]);

  const [isDangerModalOpen, setIsDangerModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [hasAttemptedAdd, setHasAttemptedAdd] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState<number | null>(null);
  const [isEmissionSourceSelected, setIsEmissionSourceSelected] = useState(false);
  const [newEmissionFormData, setNewEmissionFormData] = useState({
    category: 'CBAM_PRRP',
    mandatory: false,
    emission_name: '',
    emission_id: 0,
    activity: null,
    unit: 't',
    purchased: false,
    cn_code: null
  });

  const [currentCategory, setCurrentCategory] = useState('');
  const [currentCategoryItems, setCurrentCategoryItems] = useState<EmissionSource[]>([]);
  const [AGCgoods, setAGCgoods] = useState([]);

  const handleEmissionNameChange = async (selectedItem: EmissionSource | null) => {
    if (selectedItem) {
      setNewEmissionFormData((prevFormData) => ({
        ...prevFormData,
        ...selectedItem,
        emission_id: selectedItem.emission_id,
      }));
  
      setIsEmissionSourceSelected(true);
      if (selectedItem.category === 'CBAM_PRRP') {
        try {
          const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
          };
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/surveys/goods/${selectedItem.emission_name}`, requestOptions);
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const data = await response.json();
          setAGCgoods(data);
        } catch (error) {
          console.error("Failed to fetch emission data", error);
          setAGCgoods([]);
        }
      }
    } else {
      setIsEmissionSourceSelected(false);
    }
  };
  
  const handleDelete = (emissionIdToDelete: number) => {
    const item = ES.find((esi) => esi.emission_id === emissionIdToDelete);
    if (item && item.mandatory) {
      setSelectedForDeletion(emissionIdToDelete);
      setIsDangerModalOpen(true);
    } else {
      ESUpdate((prevES) => prevES.filter((esi) => esi.emission_id !== emissionIdToDelete));
    }
  };

  const handleRevertToPrevious = () => {
    setIsDangerModalOpen(false);
    setSelectedForDeletion(null);
  };

  const handleRemoval = () => {
    if (selectedForDeletion != null) {
      ESUpdate((prevES) => prevES.filter((esi) => esi.emission_id !== selectedForDeletion));
      setIsDangerModalOpen(false);
      setSelectedForDeletion(null);
    }
  };

  const getCategoryDisplayName = (categoryCode: string) => {
    const categoryDisplayNames: Record<string, string> = {
      'CBAM_PRRP': t('survey.step4.dynamicTable.categoryDisplayName.precursor'),
      'CBAM_PROI': t('survey.step4.dynamicTable.categoryDisplayName.otherInput'),
      'CBAM_PRFG': t('survey.step4.dynamicTable.categoryDisplayName.fuelGas'),
    };
    return categoryDisplayNames[categoryCode] || categoryCode;
  };

  const unitList = [
    { id: 't', text: t('survey.step4.dynamicTable.unitText.tonne') },
    { id: 'kg', text: t('survey.step4.dynamicTable.unitText.kg') },
  ];

  const headers: DataTableHeader[] = [
    { key: 'mandatory', header: '', isSortable: false, },
    {
      key: 'emission_name',
      header: t('survey.step4.dynamicTable.header.emission_name'),
      isSortable: false,
    },
    {
      key: 'cn_code', // New column for unique identifier
      header: 'CN Code',
      isSortable: false,
    },
    {
      key: 'category',
      header: t('survey.step4.dynamicTable.header.category'),
      isSortable: false,
    },
    {
      key: 'activity',
      header: t('survey.step4.dynamicTable.header.activity'),
      isSortable: false,
    },
    {
      key: 'unit',
      header: t('survey.step4.dynamicTable.header.unit'),
      isSortable: false,
    },
    {
      key: 'purchased',
      header: t('survey.step4.dynamicTable.header.purchased'),
      isSortable: false,
    },
  ];

  const handleAddNew = (category: string) => {
    let items: EmissionSource[];
    switch (category) {
      case 'CBAM_PRRP':
        items = PrecursorsList;
        break;
      case 'CBAM_PRFG':
        items = FuelGasList;
        break;
      case 'CBAM_PROI':
        items = OtherInputList;
        break;
      default:
        items = [];
    }

    // Removed filtering logic to allow multiple selections of the same item
    setCurrentCategory(category);
    setCurrentCategoryItems(items);

    setNewEmissionFormData({
      ...newEmissionFormData,
      category: category,
      emission_name: '',
    });
    setHasAttemptedAdd(false);
    setIsAddModalOpen(true);
  };

  const handleChange = (emissionId: number, name: string, value: any) => {
    ESUpdate((prevES) =>
      prevES.map((item) =>
        item.emission_id === emissionId ? { ...item, [name]: value } : item
      )
    );
  };

  const cellComponent = (cell: any, index: number, row: EmissionSource) => {
    switch (cell[0]) {
      case 'emission_name':
        return (
          <span
            className="some-class-for-styling"
            id={`cell-text-${cell[0]}-${index}`}
          >
            {cell[1]}
          </span>
        );
      case 'cn_code': // Handle the new unique_id column
        return (
          <span
            className="some-class-for-styling"
            id={`cell-text-${cell[0]}-${index}`}
          >
            {row.cn_code}
          </span>
        );
      case 'category':
        return (
          <span
            className="some-class-for-styling"
            id={`cell-text-${cell[0]}-${index}`}
          >
            {getCategoryDisplayName(cell[1])}
          </span>
        );
      case 'activity':
        return (
          <div className='activity-input-wrapper'>
            <NumberInput
              step={0.01}
              hideSteppers={true}
              name="activity"
              id={`cell-textinput-activity-${row.emission_id}`}
              defaultValue={cell[1]}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(row.emission_id!, "activity", event.target.value)}
              invalid={hasAttemptedNextStep && (!cell[1] || cell[1] <= 0)}
            />
          </div>
        );
      case 'unit':
        return (
          <div className='unit-select-wrapper'>
            <Select
              id={`cell-select-unit-${row.emission_id}`}
              defaultValue={cell[1]}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => handleChange(row.emission_id!, "unit", event.target.value)}
              labelText=""
            >
              {unitList.map(unit => (
                <SelectItem key={unit.id} value={unit.id} text={unit.text} />
              ))}
            </Select>
          </div>
        );
      case 'purchased':
        return (
          row.category === 'CBAM_PRRP' && (
            <Checkbox
              id={`cell-checkbox-purchased-${row.emission_id}`}
              checked={cell[1]}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(row.emission_id!, "purchased", event.target.checked)}
            />
          )
        );
      default:
        return <></>;
    }
  };
  
  return (
    <>
      <DataTable rows={ES} headers={headers} >
        {() => {
          return (
            <TableContainer>
              <TableToolbar>
                <TableToolbarContent>
                  <div className="primary-overflow-button">
                    <OverflowMenu ariaLabel="add-overflow-menu" renderIcon={() => <AddLarge fill="white" />} flipped size="lg" align="bottom">
                      <OverflowMenuItem
                        itemText="Add Precursor" requireTitle
                        onClick={() => handleAddNew('CBAM_PRRP')}
                      />
                      <OverflowMenuItem
                        itemText="Add Fuel/Gas" requireTitle
                        onClick={() => handleAddNew('CBAM_PRFG')}
                      />
                      <OverflowMenuItem
                        itemText="Add Other Input" requireTitle
                        onClick={() => handleAddNew('CBAM_PROI')}
                      />
                    </OverflowMenu>
                  </div>
                </TableToolbarContent>
              </TableToolbar>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableHeader
                          key={header.key}
                          isSortable={header.isSortable}
                          isSortHeader
                        >
                          {header.header}
                        </TableHeader>
                      );
                    })}
                    <TableHeader
                      key="actions-header"
                      isSortable={false}
                      colSpan="2"
                    >
                      {''}
                    </TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody style={{ minHeight: '200px' }}>
                  {ES.map((esi: EmissionSource, rowIndex) => {
                    return (
                      <TableRow key={`table-row-${esi.emission_name}-${esi.emission_id}`}>
                        <TableCell className="mandatory-field">
                          {esi.mandatory && (
                            <IconButton
                              label="Required"
                              kind="ghost"
                              align="top"
                            >
                              <StarFilled style={{ cursor: 'pointer' }} />
                            </IconButton>
                          )}
                        </TableCell>
                        {headers.slice(1).map((header) => {
                          const cellValue = esi[header.key as keyof EmissionSource];
                          return (
                            <TableCell key={`table-cell-${esi.emission_name}-${header.key}-${esi.emission_id}`}>
                              {cellComponent([header.key, cellValue], rowIndex, esi)}
                            </TableCell>
                          );
                        })}
                        <TableCell key={`edit-cell-${esi.emission_id}`} className="action-buttons">
                          <IconButton
                            label="Edit"
                            kind="ghost"
                            align="top"
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell key={`delete-cell-${esi.emission_id}`} className="action-buttons">
                          <IconButton
                            kind="ghost"
                            label="Delete"
                            align="top"
                            onClick={() => handleDelete(esi.emission_id)}
                          >
                            <TrashCan />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          );
        }}
      </DataTable>
      {isDangerModalOpen && (
        <Modal
          open={isDangerModalOpen}
          danger
          modalHeading={t('survey.step4.dangermodal.heading')}
          modalLabel={t('survey.step4.dangermodal.label')}
          primaryButtonText={t('survey.step4.dangermodal.primaryButtonText')}
          secondaryButtonText={t('survey.step4.dangermodal.secondaryButtonText')}
          onRequestClose={handleRevertToPrevious}
          onRequestSubmit={handleRemoval}
        >
          <p>{t('survey.step4.dangermodal.paragraph')}</p>
        </Modal>
      )}
      {isAddModalOpen && (
        <Modal
          open={isAddModalOpen}
          modalHeading="Add New Emission Source"
          modalLabel="New Emission Source"
          primaryButtonText="Add"
          secondaryButtonText="Cancel"
          onRequestClose={() => {
            setIsAddModalOpen(false);
            setIsEmissionSourceSelected(false);
            setAGCgoods([]);
          }}
          onRequestSubmit={() => {
            setHasAttemptedAdd(true);
            if (!isEmissionSourceSelected || (!isItemSelected && currentCategory === 'CBAM_PRRP')) {
              return;
            }

            ESUpdate((prevES) => [
              ...prevES,
              {
                ...newEmissionFormData,
       
              }
            ]);
            setIsAddModalOpen(false);
            setIsEmissionSourceSelected(false);
            setAGCgoods([]);
          }}
        >
         <ComboBox
    id="emission-name-dropdown"
    titleText="Emission Source"
    label="Choose an emission source"
    items={currentCategoryItems}
    itemToString={(item: any) => (item && item.emission_name ? item.emission_name : '')}
    onChange={(event: ComboBoxEvent) => handleEmissionNameChange(event.selectedItem)}
    invalid={!isEmissionSourceSelected && hasAttemptedAdd}
    invalidText="Please select an emission source"
  />
          <br />
          {currentCategory === 'CBAM_PRRP' && AGCgoods.length > 0 ? (
            <AGCGoodsTable AGCgoods={AGCgoods} onUpdateCNselection={updateCNselection} invalid={!isItemSelected && hasAttemptedAdd && isEmissionSourceSelected && currentCategory === 'CBAM_PRRP'} />
          ) : (
            <div style={{ height: '230px' }} />
          )}
          {!isItemSelected && hasAttemptedAdd && isEmissionSourceSelected && currentCategory === 'CBAM_PRRP' && (
            <div style={{ color: 'var(--cds-text-error, #da1e28)', marginTop: '5px', fontSize: 'var(--cds-label-01-font-size, 0.75rem)' }}>
              Please select a CN code for your emission source before proceeding.
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default DynamicEmissionTable;
