import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const SessionExpiredComponent = () => {

  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  let surveyId = searchParams.get('surveyId');

  useEffect(() => {
    navigate(`/?surveyId=${surveyId}`);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, [navigate, surveyId]);


  return (<div>
    <h1>Session expired</h1>
    <p>Redirecting in 2 seconds...</p>
  </div>);
};

export default SessionExpiredComponent;