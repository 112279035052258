import React from 'react';

interface VideoEmbedProps {
  src: string;
  title: string;
  width?: string;
  height?: string;
  key?: string; // Add key prop
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ src, title, width = '100%', height = '100%', key }) => {
  return (
    <div style={{ padding: '56.25% 0 0 0', position: 'relative', width, height }}>
      <iframe
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
        title={title}
        key={key} // Use key prop
      ></iframe>
    </div>
  );
};

export default VideoEmbed;
